import React from 'react'

import bar from '@images/extras/barArea.svg'
import bike from '@images/extras/bike.svg'
import childrenPlayArea from '@images/extras/childrenPlayArea.svg'
import conferenceRoom from '@images/extras/conferenceRoom.svg'
import family from '@images/extras/family.svg'
import firstClass from '@images/extras/firstClass.svg'
import hanger from '@images/extras/hanger.svg'
import infantCompartment from '@images/extras/infantCompartment.svg'
import luggage from '@images/extras/luggage.svg'
import luggageLocker from '@images/extras/luggageLocker.svg'
import noPhone from '@images/extras/noPhone.svg'
import phone from '@images/extras/phone.svg'
import powerOutlet from '@images/extras/powerOutlet.svg'
import prioritySeat from '@images/extras/prioritySeat.svg'
import quiet from '@images/extras/quite.svg'
import restaurant from '@images/extras/restaurant.svg'
import secondClass from '@images/extras/secondClass.svg'
import wheel from '@images/extras/steering-wheel.png'
import stroller from '@images/extras/stroller.svg'
import trashBin from '@images/extras/trashBin.svg'
import wc from '@images/extras/wc.svg'
import wheelchair from '@images/extras/wheelchair.svg'
import bem from '@lib/bem'
import seatSelection from '@lib/seatSelection'

import '@components/SeatSelection/Seat/Service/index.scss'

export const serviceLabels = {
  WHEEL: wheel,
  WC: wc,
  wc_sign: wc,
  STROLLER: stroller,
  WHEELCHAIR: wheelchair,
  RESTAURANT: restaurant,
  LUGGAGE: luggage,

  '2nd_class_sign': secondClass,
  no_phone_area_sign: noPhone,
  '1st_class_sign': firstClass,
  quiet_sign: quiet,
  trash_bin_sign: trashBin,
  luggage_sign: luggage,
  bike_sign: bike,
  phone_area_sign: phone,
  family_area_sign: family,
  prm_sign: wheelchair,
  stroller_sign: stroller,
  bar_area_sign: bar,
  restaurant_car_sign: restaurant,
  power_outlet_sign: powerOutlet,
  conference_compartment_sign: conferenceRoom,
  luggage_locker_sign: luggageLocker,
  children_play_area_sign: childrenPlayArea,
  coat_rack_sign: hanger,
  infant_area_sign: infantCompartment,
  priority_seats_sign: prioritySeat,
}

export type ServiceSeatCode = keyof typeof serviceLabels

export interface LabeledSeatProps {
  position: Vector
  code: ServiceSeatCode
  size?: Seat.Size
}

const darkBackgroundIcons = ['1st_class_sign', '2nd_class_sign']

const ServiceSeat = ({ position, code, size }: LabeledSeatProps) => {
  const gridPlacement = seatSelection.getGridPlacement(position, size)

  return (
    <div
      className={bem('seat-selection-service-seat', { dark: darkBackgroundIcons.includes(code) })}
      style={{ ...gridPlacement }}
    >
      <img src={serviceLabels[code]} alt={code} className={bem('seat-selection', 'labeled-seat', { code })} />
    </div>
  )
}

export default ServiceSeat
