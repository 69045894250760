import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Connection from '@components/Connection'
import Money from '@components/Money'
import { Sorting } from '@enums'
import sortUtils from '@lib/sorting'
import { useConnectionFilters } from '@stores/connectionFilters'
import { Icon, Tabs } from '@ui'

import '@components/JourneyList/TransportTabs/index.scss'

interface TransportTabsProps {
  connections: Connection[]
  transportListType: Lowercase<Vehicle.Code>[]
  isLoading: boolean
}

const filterByType = (connections: Connection[], type: Lowercase<Vehicle.Code>) =>
  connections.filter(({ segments }) =>
    segments.some(({ vehicle }) => vehicle?.vehicleType?.code.toLowerCase() === type),
  )

const TransportTabs = ({ connections, transportListType, isLoading }: TransportTabsProps): ReactElement => {
  const { t } = useTranslation()
  // ToDo: revert default
  const [index, setIndex] = useState<Lowercase<Vehicle.Code>>('train')
  const [, setFilters] = useConnectionFilters()
  const filteredTransport = useMemo(
    () =>
      transportListType.filter(code =>
        connections.some(c => c.segments.some(({ vehicle }) => vehicle?.vehicleType?.code.toLowerCase() === code)),
      ),
    [transportListType, connections],
  )
  const currentType = useMemo(
    () => (filteredTransport.includes(index) ? index : filteredTransport[0]),
    [filteredTransport, index],
  )

  useEffect(() => {
    if (isLoading) return
    // istanbul ignore next
    if (filteredTransport.length === 0) return

    setIndex(currentType)
    setFilters({ transportMode: [currentType] })
  }, [connections, isLoading, filteredTransport, currentType, setFilters])

  const options = useMemo(
    () =>
      filteredTransport.map(code => {
        const cheapest = sortUtils.sortConnectionList(filterByType(connections, code), Sorting.Price)[0]
        const fastest = sortUtils.sortConnectionList(filterByType(connections, code), Sorting.Duration)[0]

        return {
          value: code,
          label: (
            <div className="row items-center cell">
              <span className="mr-3">
                <Icon name={code} size="medium" />
              </span>
              <div className="transport-mode__tabs column cell">
                <span className="transport-mode__tabs-title row start">{t(`journeyList.transport.${code}`)}</span>
                <span className="transport-mode__tabs-description row wrap start">
                  <span>
                    <Money {...cheapest.cheapestTotalAdultPrice} />
                    <span className="transport-mode__divider" />
                  </span>
                  <span>
                    <Connection.Duration departureTime={fastest.departureTime} arrivalTime={fastest.arrivalTime} />
                  </span>
                </span>
              </div>
            </div>
          ),
        }
      }),
    [connections, filteredTransport, t],
  )

  const handleClick = (value: Lowercase<Vehicle.Code>): void => {
    setFilters({ transportMode: [value] })
    setIndex(value)
  }

  return (
    <>
      {options.length > 0 && (
        <div className="transport-mode mb-lg-5">
          <Tabs options={options} value={index} onClick={handleClick} />
        </div>
      )}
    </>
  )
}

export default TransportTabs
