import accommodation from '@images/extras/accomodation.png'
import luggage from '@images/extras/baggage.png'
import bike from '@images/extras/bicycle.png'
import carbon from '@images/extras/carbon.png'
import change from '@images/extras/change.png'
import disabledCouchcard from '@images/extras/disabledCouchcard.png'
import fast from '@images/extras/fast.png'
import freeSeat from '@images/extras/freeSeat.png'
import golf from '@images/extras/golf.png'
import help from '@images/extras/help.png'
import insurance from '@images/extras/insurance.png'
import lounge from '@images/extras/lounge.png'
import meal from '@images/extras/meal.png'
import metro from '@images/extras/metro.png'
import pet from '@images/extras/pet.png'
import seat from '@images/extras/seat-reservation.png'
import frontSeat from '@images/extras/seat.png'
import seniorCoachcard from '@images/extras/seniorCoachcard.png'
import station from '@images/extras/station.png'
import ticketsByPost from '@images/extras/ticketsByPost.png'
import vehicle from '@images/extras/vehicle.png'
import youngCoachcard from '@images/extras/youngCoachcard.png'

export const OFFLOADING_CODE = 'OFFLOADING'
export const MOTORCYCLE_CODE = 'MOTO'
export const SEGMENT_ANCILLARY = ['ACCOMMODATION']
export const PAX_CATEGORY = ['INSURANCE', 'HELP', 'BOARDING']
export const RESERVATION_ANCILLARIES = ['INSURANCE']
export const STATIC_PRICE_ANCILLARIES = ['METRO', 'BOARDING']

// temporarily solution before platform implementation
export const PAX_UNLIMIT_CATEGORY = ['CARBON']
export const PAX_CARRIER_ANCILLARY = ['IRYO']

export const SEPARATOR = ' · '
export const SEAT_CODE = 'SEAT'

export const icons: Partial<Record<AncillaryCategory, string>> = {
  PETS: pet,
  LUGGAGE: luggage,
  EQUIPMENT: golf,
  BICYCLE: bike,
  VEHICLE: vehicle,
  INSURANCE: insurance,
  MEAL: meal,
  CARBON: carbon,
  HELP: help,
  SEAT: seat,
  ACCOMMODATION: accommodation,
  METRO: metro,
  LEAD: frontSeat,
  BOARDING: station,
  LOUNGE: lounge,
  FASTTRACK: fast,
  TICKETSBYPOST: ticketsByPost,
  CHANGE: change,
  YOUNGCOACHCARD: youngCoachcard,
  SENIORCOACHCARD: seniorCoachcard,
  DISABLEDCOACHCARD: disabledCouchcard,
  FREESEAT: freeSeat,
}

interface CategoryMapping {
  category: AncillaryCategory
  codes: string[]
}

export const categoryMappings: CategoryMapping[] = [
  { category: 'ACCOMMODATION', codes: ['LCS', 'UCS', 'CCS', 'BCS', 'SBCS', 'FCS', 'TCS', 'ROO', 'BED', 'FAM'] },
  { category: 'ALERT', codes: ['SMS', 'EMAIL'] },
  { category: 'BICYCLE', codes: ['BIKE', 'BICI'] },
  { category: 'EQUIPMENT', codes: ['GOLF'] },
  { category: 'LUGGAGE', codes: ['BAG'] },
  { category: 'PETS', codes: ['PET'] },
  { category: 'VEHICLE', codes: ['AUTO', 'VAN', 'MOTO', 'OFFLOADING', 'VEHICLE'] },
  { category: 'INSURANCE', codes: ['INSURANCE-CAPEMISA', 'INSURANCECAPEMISA'] },
  {
    category: 'MEAL',
    codes: [
      'BKE',
      'BKF',
      'LNP',
      'LNT',
      'IBS',
      'BKS',
      'NME',
      'HSN',
      'KSM',
      'GFM',
      'DBM',
      'LFM',
      'LSM',
      'NLM',
      'VGM',
      'VLM',
      'MOM',
      'MGR',
      'ISINGULARZERO',
      'ISINGULARONE',
      'ISINGULARTWO',
      'ISINGULARTHREE',
      'CSINGULARONE',
      'CSINGULARTWO',
      'CSINGULARTHREE',
      'CSINGULARFOUR',
      'CSINGULARFIVE',
      'CSINGULARSIX',
      'CSINGULARSEVEN',
      'CSINGULAREIGHT',
      'LMMEAL',
      'BRKONEGR',
      'BRKTWOGR',
      'SNCKGR',
      'SNCKGRL',
      'MENDGR',
      'LNCHTHREE',
      'ISINGULARFOUR',
      'ISINGULARFIVE',
    ],
  },
  { category: 'CARBON', codes: ['GREEN', 'GREENTWO', 'OFFSET'] },
  { category: 'HELP', codes: ['REDUCEDMOBILITY'] },
  { category: 'SEAT', codes: ['SEAT'] },
  { category: 'METRO', codes: ['METROONE', 'METROTEN'] },
  { category: 'LEAD', codes: ['FRONTSEAT'] },
  { category: 'BOARDING', codes: ['FEE'] },
  { category: 'LOUNGE', codes: ['LOUNGE'] },
  { category: 'FASTTRACK', codes: ['FASTTRACKHEATHROW'] },
  { category: 'TICKETSBYPOST', codes: ['TBP'] },
  { category: 'CHANGE', codes: ['CNG'] },
  { category: 'YOUNGCOACHCARD', codes: ['YCC'] },
  { category: 'SENIORCOACHCARD', codes: ['SCC'] },
  { category: 'DISABLEDCOACHCARD', codes: ['DCC'] },
  { category: 'FREESEAT', codes: ['FSN'] },
]
