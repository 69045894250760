import React, { memo, ReactElement, useState } from 'react'

import JourneyCardLayout, { JourneyCardLayoutProps } from '@components/JourneyCard/Layout'
import SeatsModal, { SeatSelectionData } from '@components/JourneyCard/SeatsModal'
import passengerUtils from '@lib/passengers'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

export interface ConnectionSelectionData extends Partial<SeatSelectionData> {
  connection: Connection
  fareClassCode: string
}

interface JourneyCardProps extends Omit<JourneyCardLayoutProps, 'onSelect'> {
  onSelect: (data: ConnectionSelectionData) => void
  seatsRequirement?: number
}

const JourneyCard = ({ onSelect, seatsRequirement, ...cardProps }: JourneyCardProps): ReactElement => {
  const { connection, fareClassFilter, isInbound } = cardProps
  const [{ passengers }] = useParams()
  const [{ seatSelection }] = useSettings()

  const [seatsModalOpened, setSeatsModalOpened] = useState<boolean>(false)
  const [selectedFareClass, setSelectedFareClass] = useState<string>(connection.cheapestFareClassCode)
  const selectFareClass = (fareClass: string): void => {
    if (seatSelection.enabledOnSearchResults) {
      setSelectedFareClass(fareClass)
      setSeatsModalOpened(true)
    } else {
      onSelect({ connection, fareClassCode: fareClass })
    }
  }
  const selectSeats = (seatsData: SeatSelectionData): void => {
    const { seats } = seatsData
    const [seat] = seats[0] ?? /* istanbul ignore next */ []

    onSelect({ connection, fareClassCode: seat?.fareClass ?? selectedFareClass, ...seatsData })
    setSeatsModalOpened(false)
  }

  return (
    <>
      {seatsModalOpened && (
        <SeatsModal
          onClose={() => setSeatsModalOpened(false)}
          connection={connection}
          onSubmit={selectSeats}
          fareClassFilter={fareClassFilter}
          fareClass={selectedFareClass}
          onChange={setSelectedFareClass}
          pax={passengerUtils.getPaxCount(passengers)}
          outboundSeatsCount={seatsRequirement}
          isInbound={isInbound}
        />
      )}
      <JourneyCardLayout onSelect={selectFareClass} {...cardProps} />
    </>
  )
}

export default memo(JourneyCard)
