import { Formik } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import FormContent from '@components/SearchForm/FormContent'
import { DirectionType } from '@components/TripDirection'
import useKavanaghIntegration from '@hooks/useKavanaghIntegration'
import usePassengersList from '@hooks/usePassengersList'
import date from '@lib/date'
import params from '@lib/params'
import passengerUtils from '@lib/passengers'
import utils from '@lib/utils'
import { useSettings } from '@queries/settings'

import '@components/SearchForm/index.scss'

export interface SearchFormSettings {
  returnTrip: boolean
  passengerType: boolean
  tripTime: boolean
  discountCodes: boolean
  paxDiscountCodes: boolean
  accommodation?: boolean
  weekly?: boolean
}

export interface SearchFormData {
  departureLocation: Location.NamedItem | null
  arrivalLocation: Location.NamedItem | null
  departureDate: string
  returnDate: string | null
  departureTime: string | null
  departureEndTime: string | null
  pax: number
  passengers: Passenger.Param[] | null
  cards: DiscountCode.Card[] | null
  accommodation?: boolean
  weekly?: boolean | null
}

export interface SearchFormProps {
  initialValues: SearchFormData
  onSubmit: (data: SearchFormData) => void
  settings: SearchFormSettings
  isDepartureDateDisabled?: (date: Date) => boolean
}

type DiscountCardsState = Record<string, string>

export interface SearchFormState {
  departureLocation: Location.NamedItem | null
  arrivalLocation: Location.NamedItem | null
  tripDirection: DirectionType
  departureDate: Date
  returnDate: Date | null
  departureTime: string | null
  passengers: Passenger.Param[]
  cards: DiscountCardsState | null
  accommodation?: boolean
  weekly?: boolean | null
}

const DISCOUNT_CARDS_INITIAL_STATE = {
  PROMOCODE: '',
}

const checkIsReturn = (value: DirectionType, date: Date | null): boolean =>
  !!date && [DirectionType.Return, DirectionType.Weekly].includes(value)

const SearchForm = (props: SearchFormProps & Directional): ReactElement => {
  const { initialValues, onSubmit, settings, direction, isDepartureDateDisabled } = props
  const [{ passengerTypesList, discountCodes }] = useSettings()
  const { getMultipleCarriersPaxList, getInitialPassengers } = passengerUtils
  const { updateJJKTypesList, isJJKEnabled } = useKavanaghIntegration()
  const list = usePassengersList()
  const passengerList = useMemo(() => {
    if (isJJKEnabled) return updateJJKTypesList(list)
    if (passengerTypesList.multiple) return getMultipleCarriersPaxList() as Passenger.Type[]

    return list
  }, [passengerTypesList.multiple, getMultipleCarriersPaxList, isJJKEnabled, updateJJKTypesList, list])

  const initialFormValues = useMemo<SearchFormState>(() => {
    const departureDate = date.parse(initialValues.departureDate)
    const departureTime = initialValues.departureTime ?? params.getDefaultDepartureTime(true, departureDate)
    const weekly = initialValues.weekly && DirectionType.Weekly
    const twoWay = initialValues.returnDate && DirectionType.Return

    const cards = (() => {
      if (!discountCodes.enabled) return null
      if (!initialValues.cards) return null

      return initialValues.cards.reduce((mem, item) => {
        return {
          ...mem,
          [item.name]: item.code,
        }
      }, DISCOUNT_CARDS_INITIAL_STATE)
    })()

    return {
      departureLocation: initialValues.departureLocation,
      arrivalLocation: initialValues.arrivalLocation,
      tripDirection: weekly || twoWay || DirectionType.OneWay,
      departureDate,
      returnDate: initialValues.returnDate ? date.parse(initialValues.returnDate) : null,
      departureTime: settings.tripTime ? departureTime : null,
      // TODO: for now we show only total amount for passengers and we will add more types in the future
      passengers: passengerTypesList.enabled
        ? getInitialPassengers(passengerList, initialValues.passengers)
        : new Array(initialValues.pax).fill({ type: 'passengers', pax: 1 }),
      cards,
      accommodation: initialValues.accommodation,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, passengerList])

  const submitForm = ({
    departureDate,
    departureLocation,
    arrivalLocation,
    passengers,
    returnDate,
    tripDirection,
    departureTime,
    cards,
    accommodation,
  }: SearchFormState): void => {
    onSubmit({
      departureLocation,
      arrivalLocation,
      departureDate: date.formatDate(departureDate),
      returnDate: checkIsReturn(tripDirection, returnDate) ? date.formatDate(returnDate as Date) : null,
      departureTime,
      departureEndTime: null,
      pax: passengers.length,
      passengers: passengerTypesList.enabled ? passengers : null,
      cards: cards ? utils.object.toArray(cards, (name, code) => ({ name, code })).filter(({ code }) => !!code) : null,
      accommodation,
      weekly: DirectionType.Weekly === tripDirection || null,
    })
  }

  return (
    <Formik enableReinitialize validateOnBlur initialValues={initialFormValues} onSubmit={submitForm}>
      <FormContent
        settings={settings}
        direction={direction}
        passengerList={passengerList}
        isDepartureDateDisabled={isDepartureDateDisabled}
      />
    </Formik>
  )
}

export default SearchForm
