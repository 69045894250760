import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { JsonApiResponse } from '@api/jsonApi'
import seatsAPI, { SeatsAvailabilityApi } from '@api/seats'
import { ApiError } from '@lib/api'
import seatSelectionUtils, { BuildSeatsProps, SeatsConnections } from '@lib/seatSelection'
import utils from '@lib/utils'
import { useParams } from '@stores/params'

interface UseSeatsQueriesProps {
  connections: SeatsConnections
  options?: BuildSeatsProps
  enabled?: boolean
}

const groupCoachesBySegment = (data: SeatsAvailabilityApi.Response['data']): Record<string, Set<string>> =>
  data.reduce(
    (segmentMap, seat) => {
      const segment = seat.relationships.segment.data.id
      const coach = seat.relationships.coach.data.id

      if (!segmentMap[segment]) {
        segmentMap[segment] = new Set([coach])
      } else {
        segmentMap[segment].add(coach)
      }

      return segmentMap
    },
    {} as Record<string, Set<string>>,
  )

interface Result extends Seat.Train.Data {
  isLoading: boolean
  error: ApiError | null
}

const useTwoStepSeatsQuery = ({ connections, enabled = true, options }: UseSeatsQueriesProps): Result => {
  const [params] = useParams()

  const availabilityParams: SeatsAvailabilityApi.Request | null = connections.outbound && {
    ...seatSelectionUtils.buildSeatsParams(connections.outbound, params, options),
    retailerPartnerNumber: params.retailerPartnerNumber,
    passengers: params.passengers?.map(({ type, pax }) => ({ type, pax })) ?? [{ type: 'PNOS', pax: 1 }],
  }
  const availability = useQuery<SeatsAvailabilityApi.Response, ApiError>({
    queryKey: ['seats', 'availability', availabilityParams],
    queryFn: () => seatsAPI.availability(availabilityParams!),
    enabled: !!availabilityParams && enabled,
  })

  const availabilityMap = useMemo(
    () => utils.array.mapBy(availability.data?.data ?? [], item => item.attributes.element_id),
    [availability.data?.data],
  )

  const scheme = useMemo(() => {
    if (!availability.data) return []

    const response = new JsonApiResponse(availability.data)
    const segmentCoachMap = groupCoachesBySegment(availability.data.data)
    const res = Object.entries(segmentCoachMap).map<Seat.Train.Segment>(([segment, coaches]) => {
      const segmentData = response.groupedData.segments[segment]
      // console.log(coaches.values().next().value)
      // console.log(Object.values(coaches)[0])
      const firstCoach = response.groupedData.coaches[coaches.values().next().value]
      const vehicle = response.parseRelationships(firstCoach).vehicle
      console.log(vehicle)
      const cars = response.parseRelationships(vehicle).coaches.map(coach => {
        const coachData = response.parseRelationships(coach)

        return {
          index: coachData.order_position,
          layout: coachData.layouts[0].attributes.code,
          label: coachData.label,
        }
      })

      return {
        index: segmentData.attributes.index,
        cars: cars,
      }
    })

    return res
  }, [availability.data])

  const layoutQuery = (layout: string) => ({
    queryKey: ['seats', 'layout', 'twoStep', layout],
    enabled: !!layout && enabled,
    staleTime: Infinity,
    cacheTime: Infinity,
    queryFn: async (): Promise<Seat.Train.Layout> => {
      const data = await seatsAPI.layout(layout)

      return {
        ...data,
        seats: data.elements.map<Seat.Entry>(seat => {
          const { label, item, id, position, size } = seat
          const availability = availabilityMap.get(id)!

          const orientationMap: Record<Seat.Train.Rotation, Seat.SeatOrientation> = {
            none: 'facingForward',
            half: 'facingBackward',
            three_quarters: 'facingLeft',
            quarter: 'facingRight',
          }

          return {
            id,
            code: item === 'seat' ? availability?.attributes.code : item,
            label,
            fareClass: null,
            vacant: availability?.attributes.status === 'available',
            price: availability?.attributes.price ?? 0,
            orientation: orientationMap[position.transformation.rotation],
            direction: 'straight',
            coordinates: position.anchor_point,
            flipped: position.transformation.horizontal_flip,
            size,
          }
        }),
      }
    },
  })

  return { scheme, layoutQuery, isLoading: availability.isLoading, error: availability.error }
}

export default useTwoStepSeatsQuery
