import React from 'react'

import SelectedSeat from '@components/TrainSeatSelection/SelectedSeat'
import bem from '@lib/bem'
import { Trans } from '@lib/i18n'

interface SelectedSeatsBreakdown {
  seats: Seat.Entry[]
  max: number
  fareClass?: string
}

const SelectedSeatsBreakdown = (props: SelectedSeatsBreakdown) => {
  const { max, seats, fareClass } = props

  return (
    <div className={bem('train-seats-selected')}>
      <h4 className="m-0 mb-3">
        <Trans
          components={[
            <span key="1" className="bolder" />,
            <span key="2" className="bolder ml-3" />,
            <span key="3" className="color-grey-70" />,
          ]}
          i18nKey="seats.selected"
          values={{ count: seats.length, max }}
        />
      </h4>
      {seats.length > 0 && (
        <div className="column">
          {seats.map((seat, index) => (
            <SelectedSeat key={seat.label} fareClass={fareClass} paxIndex={index} seat={seat} />
          ))}
        </div>
      )}
    </div>
  )
}

export default SelectedSeatsBreakdown
