import React, { useMemo, useState } from 'react'

import SeatsLoader from '@components/JourneyList/SeatsLoader'
import SeatSelection from '@components/SeatSelection'
import TrainSeatSelection from '@components/TrainSeatSelection'
import { SeatsList } from '@hooks/useSeatsController'
import { useSeatsSelection } from '@hooks/useSeatsSelection'
import seatSelectionUtils from '@lib/seatSelection'
import useSeatsQueries from '@queries/seats'
import useTwoStepSeatsQuery from '@queries/seats/twoSteps'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

export interface SeatSelectionData {
  seats: SeatsList
  passengerCard?: DiscountCard.Item | null
}

interface Props {
  onClose: () => void
  connection: Connection
  fareClassFilter?: string | null
  fareClass: string
  onChange: (fareClass: string) => void
  onSubmit: (data: SeatSelectionData) => void
  outboundSeatsCount?: number
  pax: number
  isInbound: boolean
}

const SeatsModal = (props: Props) => {
  const { onClose, connection, fareClassFilter, fareClass, onChange, onSubmit, outboundSeatsCount, pax, isInbound } =
    props
  const [params] = useParams()
  const [{ paxDiscountCodes, seatSelection }] = useSettings()

  const isLimitedDiscountFlow = paxDiscountCodes.enabled && paxDiscountCodes.displayOn === 'search_results'
  const isTrain = connection.segments[0].vehicle?.vehicleType?.code === 'TRAIN'

  const seatsProps = {
    connections: { outbound: connection, inbound: null },
    options: { limitations: isLimitedDiscountFlow, fareClass: isTrain ? fareClass : null },
  }

  const [data, meta, refetch] = useSeatsQueries({ ...seatsProps, enabled: seatSelection.flowType === 'single_step' })
  const trainSeatsTwoStep = useTwoStepSeatsQuery({ ...seatsProps, enabled: seatSelection.flowType === 'two_step' })
  const isSeatsLoaded =
    seatSelection.flowType === 'single_step'
      ? meta.status.every(status => status === 'success') && !!data.filter(Boolean).length
      : !trainSeatsTwoStep.isLoading

  const { selectedSeats, toggleSeat, reset: resetSelected } = useSeatsSelection()
  const [segmentCursor, setSegmentCursor] = useState<number>(0)
  const lastIndex = useMemo(() => (isInbound ? connection.segments.length : 0), [connection, isInbound])

  const selectedSegmentSeats = useMemo(() => selectedSeats[segmentCursor] ?? [], [segmentCursor, selectedSeats])
  const previousSegmentSeats = useMemo(() => selectedSeats[segmentCursor - 1], [segmentCursor, selectedSeats])

  const filteredLayout = useMemo(() => {
    const [seat] = previousSegmentSeats ?? []
    const filter = seat?.fareClass ?? fareClassFilter

    return filter && data.length
      ? seatSelectionUtils.filterLayoutByFareClass(data.flat() as Seat.Data[], filter)
      : data.flat()
  }, [previousSegmentSeats, fareClassFilter, data])

  const handleSubmit = (passengerCard?: DiscountCard.Item | null): void => {
    const segmentsCount =
      seatSelection.flowType === 'two_step' ? trainSeatsTwoStep.scheme.length : connection.segments.length
    if (segmentsCount > segmentCursor + 1) {
      setSegmentCursor(segmentCursor + 1)
      return
    }

    onSubmit({ seats: selectedSeats, passengerCard })
  }

  const handleFareClassChange = (fareClass: string): void => {
    resetSelected()
    setSegmentCursor(0)
    onChange(fareClass)
  }

  const handleSeatToggle = (seat: Seat.Entry, key?: keyof Seat.Entry): void => {
    toggleSeat(seat, segmentCursor + lastIndex, segmentCursor, key)
  }

  const trainSeatsSingleStep = useMemo(
    () => seatSelectionUtils.transformIntoTrainSeats(filteredLayout),
    [filteredLayout],
  )
  const trainSeats = seatSelection.flowType === 'single_step' ? trainSeatsSingleStep : trainSeatsTwoStep

  return (
    <>
      {!isSeatsLoaded && <SeatsLoader loading error={meta.error?.code} refetch={refetch} onClose={onClose} />}
      {isSeatsLoaded && !isTrain && (
        <SeatSelection
          opened
          layout={filteredLayout}
          selectedSeats={selectedSegmentSeats}
          reservedSeatsCount={params.pax}
          outboundSeatsCount={outboundSeatsCount ?? previousSegmentSeats?.length}
          currentCursor={segmentCursor}
          onSelect={seat => handleSeatToggle(seat, 'id')}
          onSubmit={handleSubmit}
          onClose={onClose}
          connection={connection}
          selectedFareClassCode={fareClass}
          showHeader={filteredLayout.length >= 2}
          onClick={resetSelected}
          isFullPrice
          requiredSeats={isLimitedDiscountFlow ? pax : undefined}
          discountDropdown={isLimitedDiscountFlow && pax === 1}
          onNavigateBack={() => setSegmentCursor(segmentCursor - 1)}
        />
      )}
      {isSeatsLoaded && isTrain && (
        <TrainSeatSelection
          {...trainSeats}
          opened
          selectedSeats={selectedSegmentSeats}
          reservedSeatsCount={params.pax}
          currentSegment={segmentCursor}
          onSelect={seat => handleSeatToggle(seat, 'id')}
          onSubmit={handleSubmit}
          onClose={onClose}
          connection={connection}
          selectedFareClassCode={fareClass}
          onFareClassChange={handleFareClassChange}
          onNavigateBack={() => setSegmentCursor(segmentCursor - 1)}
        />
      )}
    </>
  )
}

export default SeatsModal
