import { useFormikContext } from 'formik'
import React, { ReactElement, useEffect } from 'react'

import config from '@config'
import amplitude from '@lib/analytics/amplitude'
import { useTranslation } from '@lib/i18n'
import AncillaryFieldSet from '@pages/Checkout/Extras/Ancillary/FieldSet'
import Seats from '@pages/Checkout/Extras/Seats'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useShowExtras } from '@pages/Checkout/hooks/useShowExtras'
import { useParams } from '@stores/params'

import '@pages/Checkout/Extras/index.scss'

interface ExtrasProps {
  seatsError: ErrorCode | null
  onSeatsError: (error: ErrorCode | null) => void
  outbound: Connection | null
  inbound: Connection | null
  isConnectionsLoading: boolean
}

const Extras = ({ onSeatsError, seatsError, isConnectionsLoading, ...rest }: ExtrasProps): ReactElement => {
  const { t } = useTranslation()
  const [{ marketingCarrierCode }] = useParams()
  const { values } = useFormikContext<CheckoutFormData>()
  const { showSeats, showAncillaries } = useShowExtras({ seatsError, values, ...rest })
  const isLoading = values.isVacancyLoading || values.isReservationLoading

  useEffect(() => {
    showAncillaries && amplitude.checkout.viewAncillaries()
  }, [showAncillaries])

  return (
    <div className="extras cell-12 column">
      <h3>{t('extras.title')}</h3>
      {showSeats && (
        <Seats
          isConnectionsLoading={isConnectionsLoading}
          onError={onSeatsError}
          enabled={config.seatAncillaryAsSelection?.[marketingCarrierCode || '']}
        />
      )}
      <AncillaryFieldSet isLoading={isLoading} formData={values} seatsError={seatsError} {...rest} />
    </div>
  )
}

export default Extras
