import React, { useState } from 'react'

import SelectedSeat from '@components/TrainSeatSelection/SelectedSeat'
import useIsMobile from '@hooks/useIsMobile'
import { Trans, useTranslation } from '@lib/i18n'
import { Icon, Modal, Divider } from '@ui'

interface Props {
  seats: Seat.Entry[]
  fareClass?: string
  max: number
}

const SelectedSeatsBreakdown = (props: Props) => {
  const { max, seats, fareClass } = props
  const [listOpened, setListOpened] = useState(false)
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <>
      <div className="row items-center gap-1" onClick={() => setListOpened(true)}>
        <div className="cell basis-auto">
          <Trans
            components={[<span key="1" className="bolder" />]}
            i18nKey="seats.selected_short"
            values={{ count: seats.length, max }}
          />
        </div>
        <Icon name="chevron-down" size="medium" />
      </div>

      <Modal onClose={() => setListOpened(false)} title={t('seats.selectedTitle')} opened={listOpened}>
        <div className="px-4 pt-3 pb-5">
          {seats.length > 0 && (
            <div className="column">
              {seats.map((seat, index) => (
                <>
                  <SelectedSeat key={seat.label} fareClass={fareClass} paxIndex={index} seat={seat} />
                  {isMobile && index !== seats.length - 1 && <Divider className="my-1" />}
                </>
              ))}
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default SelectedSeatsBreakdown
