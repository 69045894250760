import React, { useMemo } from 'react'

import Amenity from '@components/JourneyCard/Footer/Amenity'
import bem from '@lib/bem'
import fare from '@lib/fare'
import { useTranslation } from '@lib/i18n'

import '@components/TrainSeatSelection/CarInfo/index.scss'

interface Props {
  label: string
  connection: Connection
  seats: Seat.Entry[]
  fareClass: string
}

const TrainCarInfo = (props: Props) => {
  const { label, connection, seats, fareClass } = props
  const { t } = useTranslation()
  const freeSeats = useMemo(() => seats.filter(item => item.vacant).length ?? /* istanbul ignore next */ 0, [seats])
  const fareFeatures = useMemo(
    () => fare.getUniqueFareFeatures([connection], { fareClassCode: fareClass }),
    [fareClass, connection],
  )

  return (
    <div className={bem('train-seats-car')}>
      <div className="column gap-2">
        <div className="cell">
          <div className="column items-center row-lg space-between-lg">
            <h4 className="bold m-0 bolder">{t('seats.train.carNumber', { index: label })}</h4>
            <div className={bem('train-seats-car', 'seats-left')}>{t('seats.seatsLeft', { count: freeSeats })}</div>
          </div>
        </div>
        <div className="cell">
          <div className="row wrap gap-2 center start-lg">
            {fareFeatures.map(feature => (
              <Amenity key={feature.id} fareFeature={feature} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrainCarInfo
