import React from 'react'

import wheel from '@images/extras/steering-wheel.png'
import bem from '@lib/bem'

const SteeringWheelSeat = () => {
  return (
    <span className={bem('seat-selection', 'seat-steering-wheel')}>
      <img src={wheel} alt="steering-wheel" />
    </span>
  )
}

export default SteeringWheelSeat
