import { useState } from 'react'

import utils from '@lib/utils'

interface Result {
  selectedSeats: Seat.Entry[][]
  toggleSeat: (seat: Seat.Entry, segmentIndex: number, cursor: number, key?: keyof Seat.Entry) => void
  reset: () => void
}

export const useSeatsSelection = (): Result => {
  const [selectedSeats, setSelectedSeats] = useState<Seat.Entry[][]>([])

  const toggleSeat = (seat: Seat.Entry, segmentIndex: number, cursor: number, key?: keyof Seat.Entry) => {
    setSelectedSeats({
      ...selectedSeats,
      [cursor]: utils.array.toggle({ ...seat, segmentIndex }, selectedSeats[cursor] ?? [], key),
    })
  }

  return { selectedSeats, toggleSeat, reset: () => setSelectedSeats([]) }
}
