import cn from 'classnames'
import React from 'react'

import bigTable from '@images/extras/largeTable.svg'
import lWall from '@images/extras/lWall.svg'
import smallTable from '@images/extras/smallTable.svg'
import smallWall from '@images/extras/smallWall.svg'
import stairs from '@images/extras/stairs.svg'
import bem from '@lib/bem'
import seatSelection from '@lib/seatSelection'

import '@components/SeatSelection/Seat/Icon/index.scss'

export const iconsMap = {
  small_wall: smallWall,
  big_table: bigTable,
  small_table: smallTable,
  l_wall_big: lWall,
  ES: stairs,
}

export type IconSeatCode = keyof typeof iconsMap

export interface IconSeatProps {
  position: Vector
  code: IconSeatCode
  orientation: Seat.SeatOrientation
  size?: Seat.Size
  flipped?: boolean
}

const customDefaultSizes: Record<string, Seat.Size> = {
  ES: { length: 1, width: 2, height: 1 },
}

const IconSeat = ({
  position,
  code,
  orientation,
  size = customDefaultSizes[code] || { length: 1, width: 1, height: 1 },
  flipped,
}: IconSeatProps) => {
  const gridPlacement = seatSelection.getGridPlacement(position, size)

  return (
    <div
      className={cn(bem('seat-selection-icon-seat'), bem('deck-item', { [orientation]: true }))}
      style={{ ...gridPlacement, scale: flipped ? '-1 1' : undefined }}
    >
      <img src={iconsMap[code]} alt={code} className={bem('seat-selection-icon-seat', 'image')} />
    </div>
  )
}

export default IconSeat
