import { useCallback } from 'react'

import useCheckoutUrl, { TripData } from '@components/JourneyList/hooks/useCheckoutUrl'
import useNavigate from '@hooks/useNavigate'
import currencyUtils from '@lib/currency'
import passengerUtils from '@lib/passengers'
import urlUtils from '@lib/url'
import { useParams } from '@stores/params'

interface Map {
  latitude: number
  longitude: number
  description: string
}

interface GPayAvailability {
  isReadyToPay: boolean
}

interface GPayPaymentData {
  apiVersion: number
  apiVersionMinor: number
  paymentMethodData: {
    type: string
    description: string
    info: {
      cardNetwork: string
      cardDetails: string
    }
    tokenizationData: {
      type: string
      token: string
    }
  }
}

interface GPayTokenData {
  statusCode: number
  paymentData: GPayPaymentData | null
}

interface UserData {
  moovitAccountId: string
  firstName: string
  lastName: string
  email: string
  phone: string
}

interface TokenData {
  paymentMethodToken: string
  paymentUserId: string
  paymentMethodId: string
}

interface MoovitIntegrationHook {
  initiateGetUserData: (data: TripData) => void
  handleNavigate: (station: Station) => void
  handleBackToWallet: () => void
  getTokenData: (price: Money) => Promise<TokenData>
  getGPayTokenData: (price: Money) => Promise<GPayTokenData>
  getGPayAvailability: () => Promise<boolean>
  enabled: boolean
  redirectionEnabled: boolean
}

const MOOVIT_RPN_APP = [845017, 762929]
const MOOVIT_RPN_WEB = [284104, 441269]
const CUSTOMER_ID = 'msCkc8_8O4wqVam3fcO0_A'

const useMoovitIntegration = (): MoovitIntegrationHook => {
  const [{ retailerPartnerNumber, pax }] = useParams()
  const buildRedirectUrl = useCheckoutUrl()
  const { checkoutRedirect } = useNavigate()
  const { getPassengerCode, getDefaultPassenger } = passengerUtils

  /* istanbul ignore next: Temporarily, will be used in the future */
  const setName = (passengers: Passenger.Param[], user: UserData): Passenger.Param[] | null => {
    const { firstName, lastName } = user
    return passengers.reduce<Passenger.Param[]>((acc, curr) => {
      const isNameExist = acc.some(({ firstName }) => firstName)
      const codes = getPassengerCode(['adult', 'senior'])

      if (codes.includes(curr.type) && !isNameExist) return [...acc, { ...curr, firstName, lastName }]

      return [...acc, curr]
    }, [])
  }
  /* istanbul ignore next: Temporarily, will be used in the future */
  const setAccountInfoCallback = (data: TripData): void => {
    window.onAccountInfoResult = (result: string): void => {
      const { passengers, ...rest } = data
      const user: UserData = JSON.parse(result)
      const passengerList = passengers ?? new Array(pax).fill(getDefaultPassenger())
      const url = buildRedirectUrl({
        ...rest,
        email: user.email,
        phone: user.phone,
        passengers: setName(passengerList, user),
      })

      checkoutRedirect(urlUtils.appendParams(url, { retailerBookingNumber: user.moovitAccountId }))
    }
  }

  /* istanbul ignore next: can't test it locally */
  const initiateGetUserData = (data: TripData): void => {
    setAccountInfoCallback(data)

    if (window.moovit) {
      window.moovit.requestAccountInfo?.()
    } else if (window.webkit) {
      window.webkit!.messageHandlers!.requestAccountInfo?.postMessage?.()
    } else {
      window.requestAccountInfo?.requestAccountInfo?.()
    }
  }

  const buildMapURL = (station: Station): string => {
    const base = 'https://moovitapp.com'
    const parameters = { tll: `${station.latitude}_${station.longitude}`, to: station.name, customerId: CUSTOMER_ID }

    return urlUtils.build([base], parameters)
  }

  /* istanbul ignore next: can't test it locally */
  const handleNavigate = (station: Station): void => {
    if (MOOVIT_RPN_WEB.includes(retailerPartnerNumber)) {
      window.open(buildMapURL(station))
      return
    }

    const mapData: Map = {
      longitude: Number(station.longitude),
      latitude: Number(station.latitude),
      description: station.name,
    }
    const jmiString = JSON.stringify(mapData)

    if (window.moovit) {
      window.moovit.requestMapNavigation?.(jmiString)
    } else if (window.webkit) {
      window.webkit!.messageHandlers!.requestMapNavigation?.postMessage?.(jmiString)
    } else {
      window.requestMapNavigation?.requestMapNavigation?.(jmiString)
    }
  }

  /* istanbul ignore next: can't test it locally */
  const handleBackToWallet = (): void => {
    if (window.moovit) {
      window.moovit.requestWalletNavigation?.()
    } else if (window.webkit) {
      window.webkit!.messageHandlers!.requestWalletNavigation?.postMessage?.()
    } else {
      window.requestWalletNavigation?.requestWalletNavigation?.()
    }
  }

  /* istanbul ignore next: can't test it locally */
  const getTokenData = useCallback((price: Money): Promise<TokenData> => {
    const jpiString = JSON.stringify({ amount: currencyUtils.create(price.fractional, price.currency).format() })

    if (window.moovit) {
      window.moovit.requestPaymentToken?.(jpiString)
    } else if (window.webkit) {
      window.webkit!.messageHandlers!.requestPaymentToken?.postMessage?.(jpiString)
    } else {
      window.requestPaymentToken?.requestPaymentToken?.(jpiString)
    }

    return new Promise(resolve => {
      window.onPaymentTokenResult = (result: string): void => {
        const token: TokenData = JSON.parse(result)

        resolve(token)
      }
    })
  }, [])

  /* istanbul ignore next: can't test it locally */
  const getGPayAvailability = useCallback((): Promise<boolean> => {
    const config = JSON.stringify({
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
          },
        },
      ],
    })

    if (window.moovit_gpay) {
      window.moovit_gpay.requestIsReadyToPay?.(config)
    } else if (window.webkit) {
      window.webkit!.messageHandlers!.requestIsReadyToPay?.postMessage?.(config)
    } else {
      window.requestPaymentToken?.requestIsReadyToPay?.(config)
    }

    return new Promise((resolve, reject) => {
      if (!window.moovit_gpay) reject('Payment method is not supported')

      window.onIsReadyToPayResult = (result: string): void => {
        const token: GPayAvailability = JSON.parse(result)

        resolve(token.isReadyToPay)
      }
    })
  }, [])

  /* istanbul ignore next: can't test it locally */
  const getGPayTokenData = useCallback((price: Money): Promise<GPayTokenData> => {
    const paymentData = JSON.stringify({
      apiVersion: 2,
      apiVersionMinor: 0,
      merchantInfo: {
        merchantName: 'Distribusion Technologies',
      },
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
              gateway: 'adyen',
              gatewayMerchantId: 'Distribusion_Moovit',
            },
          },
        },
      ],
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPrice: currencyUtils.create(price.fractional, price.currency).format({
          precision: 2,
          symbol: '',
          decimal: '.',
        }),
        currencyCode: price.currency,
      },
    })

    if (window.moovit_gpay) {
      window.moovit_gpay.requestPaymentData?.(paymentData)
    } else if (window.webkit) {
      window.webkit!.messageHandlers!.requestPaymentData?.postMessage?.(paymentData)
    } else {
      window.requestPaymentToken?.requestPaymentData?.(paymentData)
    }

    return new Promise((resolve, reject) => {
      if (!window.moovit_gpay) reject('Payment method is not supported')

      window.onPaymentDataResult = (result: GPayTokenData): void => {
        resolve(result)
      }
    })
  }, [])

  return {
    initiateGetUserData,
    handleNavigate,
    handleBackToWallet,
    getTokenData,
    getGPayTokenData,
    getGPayAvailability,
    enabled: MOOVIT_RPN_APP.includes(retailerPartnerNumber),
    redirectionEnabled: [...MOOVIT_RPN_WEB, ...MOOVIT_RPN_APP].includes(retailerPartnerNumber),
  }
}

export default useMoovitIntegration
