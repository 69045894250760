import React, { useMemo, useState } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import utils from '@lib/utils'
import { Button, Icon } from '@ui'
import ButtonGroup from '@ui/ButtonGroup'

import '@components/TrainSeatSelection/CarSelector/index.scss'

interface Props {
  cars: Seat.Train.Car[]
  onChange: (carIndex: number) => void
  value: number
}

const scrollThreshold = 7

const CarSelection = (props: Props) => {
  const { cars, value, onChange } = props
  const isMobile = useIsMobile()
  const sorted = useMemo(() => cars.sort((a, b) => a.index - b.index), [cars])
  const [cursor, setCursor] = useState(0)
  const cursorMax = cars.length - scrollThreshold
  const showScroll = cars.length > scrollThreshold && !isMobile
  const visibleCars = showScroll ? sorted.slice(cursor, cursor + scrollThreshold) : cars
  const selectCar = (index: number) => {
    onChange(index)

    if (showScroll) {
      if (index >= cursor + scrollThreshold - 1) {
        setCursor(cursor => utils.number.clamp(cursor + 1, 0, cursorMax))
      }

      if (index < cursor + 1) {
        setCursor(cursor => utils.number.clamp(cursor - 1, 0, cursorMax))
      }
    }
  }

  return (
    <div className="car-selector">
      <ButtonGroup flat={isMobile}>
        {visibleCars.map(({ index, label }) => (
          <Button
            key={label}
            onClick={() => selectCar(index)}
            variant={value === index ? 'filled' : 'outline'}
            color={value === index ? 'secondary' : 'grey'}
            className={bem('car-selector', 'car-button')}
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>
      {showScroll && (
        <>
          <Button
            variant="outline"
            color="grey"
            round
            disabled={cursor === 0}
            onClick={() => setCursor(cursor => cursor - 1)}
            className={bem('car-selector', 'scroll-button', { left: true })}
          >
            <Icon name="chevron-left" size="medium" />
          </Button>
          <Button
            variant="outline"
            round
            disabled={cursor === cursorMax}
            onClick={() => setCursor(cursor => cursor + 1)}
            color="grey"
            className={bem('car-selector', 'scroll-button', { right: true })}
          >
            <Icon name="chevron-right" size="medium" />
          </Button>
        </>
      )}
    </div>
  )
}

export default CarSelection
