import { isArray } from 'lodash'

type Relationships<Types extends JsonApi.Reference> = {
  [K in Types['type']]: Record<string, Extract<Types, { type: K }>>
}

export class JsonApiResponse<Data extends JsonApi.Item, Included extends JsonApi.Item> {
  groupedData: Relationships<Included>

  constructor(data: JsonApi.Response<Data, Included>) {
    this.groupedData = this.groupByType(data.included)
  }

  private groupByType<T extends JsonApi.Reference>(included: T[]): Relationships<T> {
    return included.reduce((mem, item) => {
      if (!mem[item.type as keyof Relationships<T>]) return { ...mem, [item.type]: { [item.id]: item } }
      ;(mem as any)[item.type][item.id] = item

      return mem
    }, {} as Relationships<T>)
  }

  parseRelationships<T extends JsonApi.ItemConfig>(item: JsonApi.Item<T>): JsonApi.ParsedItem<T> {
    const parsedRelationships = Object.entries(item.relationships).reduce(
      (mem, [key, value]) => ({ ...mem, [key]: this.getRelation(value) }),
      {},
    )

    return {
      ...item.attributes,
      ...parsedRelationships,
    }
  }

  getRelation(value: any): any | any[] {
    const data = value.data || value

    return isArray(data)
      ? data.map(item => this.getRelation(item))
      : this.groupedData[data.type as keyof Relationships<Included>][data.id]
  }
}
