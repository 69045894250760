import React, { ReactElement, ReactNode, useCallback, useMemo } from 'react'

import { DiscountCardsResponse } from '@api/discountCards'
import DeckFrame from '@components/SeatSelection/DeckFrame'
import Legend from '@components/SeatSelection/Legend'
import SeatComponent from '@components/SeatSelection/Seat'
import SteeringWheel from '@components/SeatSelection/Seat/SteeringWheel'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'

import '@components/SeatSelection/Deck/index.scss'

interface DeckProps {
  seats: Seat.Entry[]
  deckSize?: Seat.Size
  priceCategories: Record<number, number>
  discountCategories: Seat.Discount[]
  onClick: (seat: Seat.Entry) => void
  selectedSeats: Seat.Entry[]
  amenities?: ReactNode
  passengerDiscountEnabled: boolean
  passengerCard: string | null
  setPassengerCard: (value: string | null) => void
  discounts?: DiscountCardsResponse | null
}

const LAST_ROW = 5
const CENTER_ROW = 2

const updateRow = (seats: Seat.Entry[], i: number): void => {
  const currentSeat = seats[i]
  const prevSeat = seats[i - 1]
  const sub = currentSeat.coordinates.y - 1

  currentSeat.coordinates.y = sub
  if (prevSeat?.coordinates.y === sub) prevSeat.coordinates.y = sub - 1
}

const updateSeats = (seats: Seat.Entry[]): Seat.Entry[] =>
  seats.reduce<Seat.Entry[]>((acc, seat) => {
    if (acc.find(s => s.coordinates.x === seat.coordinates.x)) return acc

    const row = seats
      .filter(s => s.coordinates.x === seat.coordinates.x)
      .sort((a, b) => a.coordinates.y - b.coordinates.y)

    const last = row.findIndex(item => item.coordinates.y === LAST_ROW)
    const center = row.findIndex(item => item.coordinates.y === CENTER_ROW)

    last >= 0 && updateRow(row, last)
    center >= 0 && updateRow(row, center)

    return [...acc, ...row.flat()]
  }, [])

const Deck = (props: DeckProps): ReactElement => {
  const {
    seats,
    priceCategories,
    passengerCard,
    setPassengerCard,
    passengerDiscountEnabled,
    onClick,
    selectedSeats,
    amenities,
    discountCategories,
    deckSize,
  } = props
  const isMobile = useIsMobile()
  const hasPaidSeats = seats.some(seat => !!seat.price)
  const hasExclusiveSeat = useCallback(
    (discount: Seat.Discount): boolean => !!discount.meta?.exclusive && discount.name === passengerCard,
    [passengerCard],
  )

  const isExclusiveSeats = useMemo(
    () => seats.some(car => car.limitations?.some(hasExclusiveSeat)),
    [hasExclusiveSeat, seats],
  )

  const isDiscountAvailable = useMemo(
    () =>
      discountCategories.some(
        ({ name, meta }) =>
          name === passengerCard && (meta?.amount_left ?? /* istanbul ignore next */ 0) > selectedSeats.length,
      ),
    [discountCategories, passengerCard, selectedSeats],
  )

  const getVacancy = useCallback(
    (seat: Seat.Entry): boolean => {
      if (!passengerDiscountEnabled) return seat.vacant
      if (isExclusiveSeats) return !!seat.limitations?.some(hasExclusiveSeat)
      if (isDiscountAvailable || !passengerCard) return !!seat.limitations?.every(item => !item.meta?.exclusive)

      return selectedSeats.some(item => item.code === seat.code)
    },
    [passengerDiscountEnabled, isExclusiveSeats, hasExclusiveSeat, isDiscountAvailable, passengerCard, selectedSeats],
  )

  const getDiscountIndex = useCallback(
    (seat: Seat.Entry): number =>
      discountCategories.findIndex(({ name }) => (seat.limitations?.[0]?.name ?? passengerCard) === name) + 1,
    [discountCategories, passengerCard],
  )

  const getDiscountCategory = useCallback(
    (seat: Seat.Entry): Seat.Discount[] | undefined => {
      if (seat.limitations?.length || isExclusiveSeats) return seat.limitations

      return discountCategories.filter(({ name }) => passengerCard === name)
    },
    [discountCategories, isExclusiveSeats, passengerCard],
  )

  return (
    <>
      <Legend
        hasSelectedSeats={selectedSeats.length > 0}
        amenities={amenities}
        hasPaidSeats={hasPaidSeats}
        priceCategories={priceCategories}
        discountCategories={discountCategories}
        passengerCard={passengerCard}
        setPassengerCard={setPassengerCard}
        passengerDiscountEnabled={passengerDiscountEnabled}
        discounts={props.discounts}
      />
      <DeckFrame orientation={isMobile ? 'up' : 'right'} size={deckSize}>
        <div className={bem('seat-selection', 'lights', { left: true })} />
        <div className={bem('seat-selection', 'lights', { right: true })} />
        <SteeringWheel />
        {updateSeats(seats).map((seat, index) => (
          <SeatComponent
            key={seat.id}
            code={seat.code}
            price={seat.price}
            priceCategory={priceCategories[seat.price ?? 0]}
            discountCategory={getDiscountCategory(seat)}
            discountIndex={getDiscountIndex(seat)}
            position={seat.coordinates}
            disabled={!seat.vacant || !getVacancy(seat)}
            label={seat.label}
            onSelect={() => {
              onClick(seat)
            }}
            selected={selectedSeats.some(item => item.code === seat.code)}
            isFirstSeat={index === 0}
            size={seat.size}
          />
        ))}
      </DeckFrame>
    </>
  )
}

export default Deck
