import { FilterFareClass } from '@lib/connections/filters/types'
import utils from '@lib/utils'

const fareFeatureIconName: Record<string, string> = {
  TOIL: 'wc',
  WIFI: 'wifi',
  PSOC: 'outlet',
  DCLE: 'broom',
  BIKE: 'bicycle',
  ACON: 'air',
  PREF: 'money-half',
  ALRO: 'comfortable-seat',
  MSYS: 'tv',
  AAFO: 'bicycle',
  UCHA: 'usb',
  NAME: 'pen-crossed',
  NREF: 'money-crossed',
  AMEN: 'pen',
  REFU: 'money-full',
  AAFR: 'wheelchair',
  MAGA: 'magazines',
  AAFP: 'folded-bicycle',
  ALSE: 'adjustable-seat',
  ASEA: 'adjustable-seat',
  APLI: 'adjustable-personal-lighting',
  ATDB: 'lounge',
  BABF: 'baby-changing-facilities',
  BCTA: 'baby-changing-facilities',
  BLUF: 'bulky-luggage-for-fee',
  BLUG: 'bulky-luggage',
  BLUN: 'bulky-luggage-included',
  CACA: 'teddy-bear',
  CAUN: 'teddy-bear',
  CBYE: 'teddy-bear',
  CSEA: 'child-seat',
  CUCA: 'teddy-bear',
  DGAT: 'door-to-door',
  DRFF: 'drinks-for-fee',
  DRIN: 'drinks',
  DRNN: 'drinks',
  DTDO: 'door-to-door',
  DTDT: 'door-to-door',
  ELUF: 'extra-luggage-for-fee',
  ELUG: 'extra-luggage',
  FBIF: 'bike-for-fee',
  FBIC: 'bicycle',
  FBIN: 'bicycle',
  FTAB: 'table-seat',
  FVAL: 'flexible-validity',
  FRSN: 'front-row-seat',
  CSER: 'restaurant',
  FSNA: 'snacks',
  SNAN: 'snacks',
  GDOG: 'giude-dog',
  LACC: 'lounge',
  LOUN: 'lounge',
  LOUF: 'lounge-fee',
  MREQ: 'mask-required',
  PALL: 'pet-allowance',
  PALF: 'pet-for-fee',
  QCAR: 'quiet',
  QCOM: 'quiet',
  REST: 'restaurant',
  SSBA: 'snowboard-bag-allowance',
  SSNO: 'snowboard-bag-allowance',
  STVA: 'table-seat',
  TABS: 'table-seat',
  SKIT: 'sleep-kit',
  VMOB: 'vending-machine',
  WIFF: 'wifi-for-fee',
  WSEA: 'wider-seats',
  RARM: 'reclining-armchairs',
  SKIF: 'snowboard-bag-for-fee',
  SEAT: 'seat-selection',
  HSAN: 'hand-sanitizer',
  SBEL: 'safety-belts',
  NOSA: 'shop-check',
  SDIS: 'social-distancing',
  PSER: 'real-time',
  INFA: 'buggy',
  FDEP: 'frequent-departures',
  CODE: 'missed-departure-flexibility',
  default: 'star',
}

const getFareFeatureIcon = (code: string): string => fareFeatureIconName[code] ?? fareFeatureIconName.default

const getSegmentFareFeatures = (segment: Segment, fareClassCode: string | null): FareFeature[] => {
  const amenities = segment.amenities
  const fareClasses = segment.marketingCarrier.fareClasses

  const fareClass = fareClasses.find(item => item.code === fareClassCode)?.fareFeatures ?? []

  return amenities && amenities.length > 0 ? [...fareClass, ...amenities] : fareClass
}

const getFareFeaturesByAmenities = (connection: Connection): FareFeature[] => {
  const fareFeatures = connection.segments.flatMap(item =>
    getSegmentFareFeatures(item, connection.cheapestFareClassCode),
  )

  return utils.array.uniqueBy(fareFeatures, 'code')
}

const getFareFeatures = (connection: Connection, fareSelection: FilterFareClass): FareFeature[] =>
  getFareClasses(connection, fareSelection).flatMap(item => item.fareFeatures)

const hasFareClass = (connection: Connection, fareClassCode: string): boolean =>
  connection.fares.some(({ fareClass: { code } }) => code === fareClassCode)

const getFareClasses = (connection: Connection, { fareClassCode }: FilterFareClass): FareClass[] => {
  const carrierFareClasses = connection.segments.flatMap(item => item.marketingCarrier.fareClasses)

  return carrierFareClasses.filter(
    ({ code }) => hasFareClass(connection, code) && (!fareClassCode || fareClassCode === code),
  )
}

const getUniqueFareClasses = (connections: Connection[], filters: FilterFareClass): FareClass[] => {
  const fareClasses = connections.flatMap(connection => getFareClasses(connection, filters))

  return utils.array.uniqueBy(fareClasses, 'code')
}

const getUniqueFareClassNames = (params: Connection[], filters: FilterFareClass): string[] =>
  utils.array.uniqueBy(getUniqueFareClasses(params, filters), 'name').map(fareClass => fareClass.name)

const getUniqueFareFeatures = (connections: Connection[], filters: FilterFareClass): FareFeature[] => {
  const allFareFeatures = connections.flatMap(connection => getFareFeatures(connection, filters))

  return utils.array.uniqueBy(allFareFeatures, 'code')
}

const getFareByCode = (code: string, connection: Connection | null): Fare | undefined => {
  if (!connection) return

  const find = (code: string) => connection.fares.find(item => item.fareClass.code === code)

  return find(code) ?? find(connection.cheapestFareClassCode)
}

const getFeatureCode = ({ code }: FareFeature): string => code

const getCommonFareFeatureCodes = (fareClasses: FareClass[]): string[] => {
  const allFeatures = fareClasses.flatMap(({ fareFeatures }) => fareFeatures)
  const featuresFrequency = utils.array.frequencyBy(allFeatures, getFeatureCode)

  return featuresFrequency.filter(([, frequency]) => frequency === fareClasses.length).map(([code]) => code)
}

const getByJourneyType = (type: FareJourneyType, connection: Connection | null): Fare | undefined =>
  connection?.fares.find(({ fareClass }) => fareClass.journeyType === type)

export default {
  getFareFeatureIcon,
  getUniqueFareClasses,
  getUniqueFareClassNames,
  getUniqueFareFeatures,
  getFareByCode,
  getCommonFareFeatureCodes,
  getByJourneyType,
  getFareFeaturesByAmenities,
  getSegmentFareFeatures,
}
