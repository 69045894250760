import { PassengerData } from '@stores/checkout'
import { useParams } from '@stores/params'

interface MoovitIntegrationHook {
  isJJKEnabled: boolean
  updateJJKTypesList: (list: Passenger.Type[]) => Passenger.Type[]
  filterJJKTypes: (list: Passenger.Type[], isReturnTrip: boolean) => Passenger.Type[]
  updateJJKPassengers: (passengers: PassengerData[]) => PassengerData[]
  isYACPassengerType: (types: string) => boolean
}

const JJK_RPN = [756974]
const YOUNG_ADULT_CODE = 'YAC'
const CUSTOM_PASSENGER_TYPES = ['PFTY', 'PSER']

const useKavanaghIntegration = (): MoovitIntegrationHook => {
  const [{ retailerPartnerNumber }] = useParams()

  const updateJJKTypesList = (list: Passenger.Type[]): Passenger.Type[] => {
    const youngAdultType = {
      code: YOUNG_ADULT_CODE,
      name: 'Young Adult Card',
      minAge: 0,
      maxAge: 97,
      id: YOUNG_ADULT_CODE,
      description: '',
      cards: [{ name: YOUNG_ADULT_CODE, code: 'Please enter your card number' }],
    }

    return [...list, youngAdultType]
  }

  const filterJJKTypes = (list: Passenger.Type[], isReturnTrip: boolean): Passenger.Type[] => {
    if (isReturnTrip) return list

    return list.filter(({ code }) => !CUSTOM_PASSENGER_TYPES.includes(code))
  }

  const updateJJKPassengers = (passengers: PassengerData[]): PassengerData[] =>
    passengers.map(passenger => {
      if (passenger.type === YOUNG_ADULT_CODE) return { ...passenger, type: 'PNOS' }

      return passenger
    })

  return {
    isJJKEnabled: JJK_RPN.includes(retailerPartnerNumber),
    isYACPassengerType: (type: string) => type === YOUNG_ADULT_CODE,
    updateJJKTypesList,
    updateJJKPassengers,
    filterJJKTypes,
  }
}

export default useKavanaghIntegration
