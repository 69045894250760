import React from 'react'

import bem from '@lib/bem'
import seatSelection from '@lib/seatSelection'

import '@components/SeatSelection/Seat/Table/index.scss'

export interface TableSeatProps {
  position: Vector
  neighbors?: Seat.SeatOrientation[]
  size?: Seat.Size
}

const TableSeat = ({ neighbors, position, size }: TableSeatProps) => (
  <div className={bem('seat-selection-table')} style={seatSelection.getGridPlacement(position, size)}>
    <div className={bem('seat-selection-table', 'deck')}>
      {neighbors?.map(orientation => (
        <div key={orientation} className={bem('seat-selection-table', 'link', { [orientation]: true })} />
      ))}
    </div>
  </div>
)

export default TableSeat
