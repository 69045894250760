import React, { ReactNode, useCallback } from 'react'

import { DeckContext } from '@components/SeatSelection/DeckFrame/context'
import bem from '@lib/bem'

import '@components/SeatSelection/DeckFrame/index.scss'

interface Props {
  children: ReactNode
  orientation: Seat.DeckOrientation
  isTrainHead?: boolean
  size?: Seat.Size
}

const DeckFrame = (props: Props) => {
  const { children, orientation, isTrainHead = false, size = { length: 15, width: 5, height: 1 } } = props

  const setDeckSize = useCallback(
    (element: HTMLDivElement) => {
      if (element) {
        element.style.setProperty('--rows', size.width.toString())
        element.style.setProperty('--columns', size.length.toString())
      }
    },
    [size.length, size.width],
  )

  return (
    <div
      className={bem('seat-selection', 'deck-wrapper', { [orientation]: true, head: isTrainHead })}
      ref={setDeckSize}
    >
      <div className={bem('seat-selection', 'deck')}>
        <DeckContext.Provider value={{ orientation, isTrainHead, size }}>{children}</DeckContext.Provider>
      </div>
    </div>
  )
}

export default DeckFrame
