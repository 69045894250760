import React, { ReactElement, useState } from 'react'

import Legend from '@components/SeatSelection/Legend'
import useIsMobile from '@hooks/useIsMobile'
import { useTranslation } from '@lib/i18n'
import seatSelectionUtils from '@lib/seatSelection'
import { Icon, Modal, Tooltip } from '@ui'

interface HeaderProps {
  seats: Seat.Entry[]
  hasSelectedSeats: boolean
}

const Header = ({ seats, hasSelectedSeats }: HeaderProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [opened, setOpened] = useState<boolean>(false)
  const hasPaidSeats = seats.some(seat => !!seat.price)
  const priceCategories = seatSelectionUtils.getPriceCategories(seats)

  const legend = (
    <Legend hasPaidSeats={hasPaidSeats} priceCategories={priceCategories} hasSelectedSeats={hasSelectedSeats} />
  )

  return (
    <div className="row items-center">
      <h3 className="mb-0 mr-2">{t('seats.title')}</h3>
      <Tooltip content={isMobile ? null : legend} action="click">
        <Icon name="info" size="medium" onClick={() => isMobile && setOpened(true)} />
      </Tooltip>
      <Modal
        title={<h3 className="mb-0">{t('seats.seatMap')}</h3>}
        className="train-seats-modal__legend"
        opened={opened}
        onClose={() => setOpened(false)}
      >
        {legend}
      </Modal>
    </div>
  )
}

export default Header
